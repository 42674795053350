
export enum EyeType {
    Eye1,
    Eye2,
    Eye3,
    Eye4,
    Eye5,
    Eye6,
    Eye7,
    Eye8,
    Eye9,
    Eye10,
    Eye11,
    Eye12
}