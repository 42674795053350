
export enum MouthType {
    Mouth1,
    Mouth2,
    Mouth3,
    Mouth4,
    Mouth5,
    Mouth6,

    Mouth7,
    Mouth8,

    Mouth1Lipstick,
    Mouth2Lipstick,
    Mouth3Lipstick,
    Mouth4Lipstick,
    Mouth5Lipstick,
    Mouth6Lipstick,
}